<template>
    <section class="content">
        <div class="container">
            <h1>Terms and Conditions</h1>

            <p class="mt-3"><i>
                Your access and use of the Services constitute your agreement to be bound by these Terms, which establishes a contractual relationship between you and NeighboursRide. If you do not agree to these Terms, you may not access or use the Services. These Terms expressly supersede prior agreements or arrangements with you. NeighboursRide may immediately terminate these Terms or any Services with respect to you, or generally cease offering or deny access to the Services or any portion thereof, at any time for any reason.
                Supplemental terms may apply to certain Services, such as policies for a particular event, activity or promotion, and such supplemental terms will be disclosed to you in connection with the applicable Services. Supplemental terms are in addition to, and shall be deemed a part of, the Terms for the purposes of the applicable Services. Supplemental terms shall prevail over these Terms in the event of a conflict with respect to the applicable Services.
                NeighboursRide may amend the Terms related to the Services from time to time. Amendments will be effective upon NeighboursRide s posting of such updated Terms at this location or the amended policies or supplemental terms on the applicable Service. Your continued access or use of the Services after such posting constitutes your consent to be bound by the Terms, as amended.
            </i>
            </p>

            <h5 class="mt-5">The Services</h5>
            <p>
                The Services constitute a technology platform that enables users of NeighboursRide mobile applications or websites provided as part of the Services (each, an “Application”) to arrange and schedule transportation and/or logistics services with independent third party providers of such services, including independent third party transportation providers and independent third party logistics providers under agreement with NeighboursRide or certain of Neighbours Ride’s affiliates (“Third Party Providers”). Unless otherwise agreed by NeighboursRide in a separate written agreement with you, the Services are made available solely for your personal, noncommercial use. YOU ACKNOWLEDGE THAT NeighboursRide DOES NOT PROVIDE TRANSPORTATION OR LOGISTICS SERVICES OR FUNCTION AS A TRANSPORTATION CARRIER AND THAT ALL SUCH TRANSPORTATION OR LOGISTICS SERVICES ARE PROVIDED BY INDEPENDENT THIRD PARTY CONTRACTORS WHO ARE NOT EMPLOYED BY Neighbours Ride OR ANY OF ITS AFFILIATES.
            </p>
            
            
            <h5 class="mt-5">License</h5>
            <p>
                Subject to your compliance with these Terms, NeighboursRide grants you a limited, non-exclusive, non-sublicensable, revocable, non-transferrable license to: (i) access and use the Applications on your personal device solely in connection with your use of the Services; and (ii) access and use any content, information and related materials that may be made available through the Services, in each case solely for your personal, noncommercial use. Any rights not expressly granted herein are reserved by NeighboursRide and NeighboursRide’s licensors
            </p>

            <h6 class="mt-4">Third Party Services and Content</h6>
            <p class=" w-75">The Services may be made available or accessed in connection with third party services and content (including advertising) that NeighboursRide does not control. You acknowledge that different terms of use and privacy policies may apply to your use of such third party services and content.</p>
            <h6 class="mt-4">Ownership</h6>
            <p class=" w-75">The Services and all rights therein are and shall remain NeighboursRide’s property or the property of NeighboursRide’s licensors.</p>


            <h5 class="mt-5">Your Use of the Services</h5>
            <h6 class="mt-4">User Account</h6>
            <p class=" w-75">In order to use most aspects of the Services, you must register for and maintain an active personal user Services account (“Account”). You must be at least 18 years of age, or the age of legal majority in your jurisdiction (if different than 18), to obtain an Account. Account registration requires you to submit to NeighboursRide certain personal information, such as your name, address, mobile phone number and age, as well as at least one valid payment method (either a credit card or accepted payment partner). You agree to maintain accurate, complete, and up-to-date information in your Account. Your failure to maintain accurate, complete, and up-to-date Account information, including having an invalid or expired payment method on file, may result in your inability to access and use the Services or NeighboursRide’s termination of these Terms with you. You are responsible for all activity that occurs under your Account, and you agree to maintain the security and secrecy of your Account username and password at all times. Unless otherwise permitted by NeighboursRide in writing, you may only possess one Account</p>
            <h6 class="mt-4">User Requirements and Conduct</h6>
            <p class=" w-75">The Service is not available for use by persons under the age of 18. You may not authorize third parties to use your Account, and you may not allow persons under the age of 18 to receive transportation or logistics services from Third Party Providers unless they are accompanied by you. You may not assign or otherwise transfer your Account to any other person or entity. You agree to comply with all applicable laws when using the Services, and you may only use the Services for lawful purposes (e.g., no transport of unlawful or hazardous materials). You will not, in your use of the Services, cause nuisance, annoyance, inconvenience, or property damage.</p>
            <h6 class="mt-4">Text Messaging</h6>
            <p class=" w-75">By creating an Account, you agree that the Services may send you text (SMS) messages as part of the normal business operation of your use of the Services. You may opt-out of receiving text (SMS) messages from NeighboursRide at any time.</p>
            <h6 class="mt-4">Network Access and Devices</h6>
            <p class=" w-75">You are responsible for obtaining the data network access necessary to use the Services. Your mobile network’s data and messaging rates and fees may apply if you access or use the Services from a wireless-enabled device and you shall be responsible for such rates and fees. You are responsible for acquiring and updating compatible hardware or devices necessary to access and use the Services and Applications and any updates thereto. NeighboursRide does not guarantee that the Services, or any portion thereof, will function on any particular hardware or devices. In addition, the Services may be subject to malfunctions and delays inherent in the use of the Internet and electronic communications.</p>


            <h5 class="mt-5">Payment</h5>
            <p>
                Payment will be made through app. Charges will be inclusive of applicable taxes where required by law. Charges paid by you are final and non-refundable, unless otherwise determined by NeighboursRide. All Charges are due immediately and payment will be facilitated by NeighboursRide using the preferred payment method designated in your Account, after which NeighboursRide will send you a receipt by email.
            </p>
            <h6 class="mt-4">Repair or Cleaning Fees</h6>
            <p class=" w-75">You shall be responsible for the cost of repair for damage to, or necessary cleaning of, Third Party Provider vehicles and property resulting from use of the Services under your Account in excess of normal “wear and tear” damages and necessary cleaning (“Repair or Cleaning”). In the event that a Third Party Provider reports the need for Repair or Cleaning, and such Repair or Cleaning request is verified by NeighboursRide in NeighboursRide reasonable discretion, NeighboursRide reserves the right to facilitate payment for the reasonable cost of such Repair or Cleaning on behalf of the Third Party Provider.</p>
            <h6 class="mt-4">Promotional Codes</h6>
            <p class=" w-75">NeighboursRide may, in NeighboursRide sole discretion, create promotional codes that may be redeemed for Account credit, or other features or benefits related to the Services and/or a Third Party Provider’s services, subject to any additional terms that NeighboursRide establishes on a per promotional code basis (“Promo Codes”). You agree that Promo Codes: (i) must be used for the intended audience and purpose, and in a lawful manner; (ii) may not be duplicated, sold or transferred in any manner, or made available to the general public (whether posted to a public form or otherwise), unless expressly permitted by NeighboursRide; (iii) may be disabled by NeighboursRide at any time for any reason without liability to NeighboursRide; (iv) may only be used pursuant to the specific terms that NeighboursRide establishes for such Promo Code; (v) are not valid for cash; and (vi) may expire prior to your use.</p>
            
            <hr class="mt-5 mb-5">

            <h5 class="mt-5">DISCLAIMER</h5>
            <p>THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE.” NEIGHBOURS RIDE DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN ADDITION, Neighbours Ride MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY OR AVAILABILITY OF THE SERVICES OR ANY SERVICES OR GOODS REQUESTED THROUGH THE USE OF THE SERVICES, OR THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. nRide  DOES NOT GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR ABILITY OF THIRD PARTY PROVIDERS. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY SERVICE OR GOOD REQUESTED IN CONNECTION THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.</p>

            <h5 class="mt-5">LIMITATION OF LIABILITY</h5>
            <p>NEIGHBOURSRIDE SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE SERVICES, EVEN IF NEIGHBOURS RIDE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NEIGHBOURS RIDE SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON THE SERVICES OR YOUR INABILITY TO ACCESS OR USE THE SERVICES; OR (ii) ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY THIRD PARTY PROVIDER, EVEN IF nRide HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NEIGHBOURSRIDE SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND NEIGHBOURS RIDE’S REASONABLE CONTROL. YOU ACKNOWLEDGE THAT THIRD PARTY TRANSPORTATION PROVIDERS PROVIDING TRANSPORTATION SERVICES REQUESTED THROUGH SOME REQUEST BRANDS MAY OFFER RIDESHARING OR PEER-TO-PEER TRANSPORTATION SERVICES AND MAY NOT BE PROFESSIONALLY LICENSED OR PERMITTED. IN NO EVENT SHALL NEIGHBOURS RIDE’S TOTAL LIABILITY BE TIED TO YOU IN CONNECTION WITH THE SERVICES FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION.</p>
            <p>NEIGHBOURSRIDE’S SERVICES MAY BE USED BY YOU TO REQUEST AND SCHEDULE TRANSPORTATION, GOODS OR LOGISTICS SERVICES WITH THIRD PARTY PROVIDERS, BUT YOU AGREE THAT NEIGHBOURS RIDE HAS NO RESPONSIBILITY OR LIABILITY TO YOU RELATED TO ANY TRANSPORTATION, GOODS OR LOGISTICS SERVICES PROVIDED TO YOU BY THIRD PARTY PROVIDERS OTHER THAN AS EXPRESSLY SET FORTH IN THESE TERMS.</p>
            <p>THE LIMITATIONS AND DISCLAIMER IN THIS SECTION 5 DO NOT PURPORT TO LIMIT LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE EXCLUDED UNDER APPLICABLE LAW.</p>
            

            <h5 class="mt-5">Indemnity</h5>
            <p>You agree to indemnify and hold NeighboursRide and its officers, directors, employees and agents harmless from any and all claims, demands, losses, liabilities, and expenses (including attorneys’ fees) arising out of or in connection with: (i) your use of the Services or services or goods obtained through your use of the Services; (ii) your breach or violation of any of these Terms; (iii) NeighboursRide’s use of your User Content; or (iv) your violation of the rights of any third party, including Third Party Providers.</p>          

            <h5 class="mt-5">Governing Law; Arbitration</h5>
            <p>Except as otherwise set forth in these Terms, these Terms shall be exclusively governed by and construed in accordance with the laws of Nigeria, excluding its rules on conflicts of laws. The Vienna Convention on the International Sale of Goods of 1980 (CISG) shall not apply. Any dispute, conflict, claim or controversy arising out of or broadly in connection with or relating to the Services or these Terms, including those relating to its validity, its construction or its enforceability (any “Dispute”) shall be first mandatorily submitted to mediation proceedings under the International Chamber of Commerce Mediation Rules (“ICC Mediation Rules”). If such Dispute has not been settled within sixty (60) days after a request for mediation has been submitted under such ICC Mediation Rules, such Dispute can be referred to and shall be exclusively and finally resolved by arbitration under the Rules of Arbitration of the International Chamber of Commerce (“ICC Arbitration Rules”). The ICC Rules' Emergency Arbitrator provisions are excluded. The Dispute shall be resolved by one (1) arbitrator to be appointed in accordance with the ICC Rules.</p>

            <hr class="mt-5 mb-5">

            <h5 class="mt-5">Other Provisions</h5>
            
            <h6 class="mt-4">Claims of Copyright Infringement</h6>
            <p class=" w-75">Claims of copyright infringement should be sent to Neighbours Ride’s designated agent or email.</p>
            <h6 class="mt-4">Notice</h6>
            <p class=" w-75">You may give notice to NeighboursRide by doing a mail to complaint@nride.co</p>
            <h6 class="mt-4">General</h6>
            <p class=" w-75">You may not assign or transfer these Terms in whole or in part without NeighboursRide’s prior written approval. You give your approval to NeighboursRide for it to assign or transfer these Terms in whole or in part, including to: (i) a subsidiary or affiliate; (ii) an acquirer of NeighboursRide’s equity, business or assets; or (iii) a successor by merger. No joint venture, partnership, employment or agency relationship exists between you, NeighboursRide or any Third Party Provider as a result of the contract between you and NeighboursRide or use of the Services.</p>
            <p class=" w-75">If any provision of these Terms is held to be illegal, invalid or unenforceable, in whole or in part, under any law, such provision or part thereof shall to that extent be deemed not to form part of these Terms but the legality, validity and enforceability of the other provisions in these Terms shall not be affected. In that event, the parties shall replace the illegal, invalid or unenforceable provision or part thereof with a provision or part thereof that is legal, valid and enforceable and that has, to the greatest extent possible, a similar effect as the illegal, invalid or unenforceable provision or part thereof, given the contents and purpose of these Terms. These Terms constitute the entire agreement and understanding of the parties with respect to its subject matter and replaces and supersedes all prior or contemporaneous agreements or undertakings regarding such subject matter. In these Terms, the words “including” and “include” mean “including, but not limited to.”</p>

            <p class="mt-5"><i>For any questions on these terms and conditions, please contact us by submitting a support
                request here or by e-mail at <a href="mailto:info@neighboursRide.com">info@neighboursRide.com</a> Neighbours
                LLC.</i></p>

        </div>
    </section>

</template>

<script>
    export default {
        name: "TermsPolicy"
    }
</script>

<style scoped lang="scss">
    .content {
        margin: 6rem 0;
    }
</style>