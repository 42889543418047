
<template>
  <section id="header">
    <div class="content">
      <img class = "logo-image" src="@/assets/logo_transparent.png" />
      <h1>Car Rentals from local hosts</h1>
      <p>Rent the perfect car for your next vacation or your business trip to a new city</p>
      <div class="mt-3 app-download-buttons">
        <a href="https://apps.apple.com/app/nride-carpool-rental/id1607959386" target="_blank">
          <img src="@/assets/images/app/appstore.png" />
        </a>

        <a href="https://play.google.com/store/apps/details?id=com.neighbours.nride" target="_blank">
          <img src="@/assets/images/app/google_play.png" />
        </a>
      </div>

      <span class="more" @click="handleClick()">Learn more about this application</span>
    </div>
  </section>

  <section id="con" class="container">
    <div class="header" ref="targetRef">
      <h1>Rental Service</h1>
      <p class="subtitle">
        Your ride is right down the street. NeighboursRide provide an easy
        access to a ride at anytime within your neighborhood. Our Rental service
        is designed to provide transportation needs for our Neighbourhood and
        community at large. The innovative technology platform enables the host
        to provide extra services to the renters such as Carwash, Extra gas,
        Extra hours and many more. It also also enables host or a car owner to
        post as many vehicles as possible for Rental or RideShare services. The
        rental vehicles are owned and operated independently by owners' and
        volunteers within your neighborhood.
      </p>
            <p class="subtitle mt-5">
        NeighborsRide provide the open access to many automobile and
        transportation needs at the convenience of your phone. Select from our
        various listed vehicles ranging from good functioning cars to luxury and
        vintage cars .Our cars are available for birthday parties, weddings
        diplomatic pick up from airport, travel trip, haulage transportation,
        moving vehicle and many more..
      </p>
    </div>
  </section>

    <section id="video" class="container">
    <div class="row vid">
      <div class="col-md-6 content">
          <h1>How to use the app?</h1>
          <p class="subtitle">Check out our step by step tutorial video on how to book a rental ride.</p>
      </div>
      
      <div class="col-md-6">
        <div
          class="embed-responsive embed-responsive-16by9"
          poster="@/assets/images/share/smartphone.png"
        >
          <iframe
            height="400"
            class="w-100 vid-height"
            src="https://www.youtube.com/embed/0m9MIbQdFH4?si=pnWWAWBQIKQP2RXX"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </section>

  <section id="experience1">
    <div class="container">
      <h5>Select Your Ride and Hit the Road</h5>
      <Carousel :items-to-show="SlideCount" class="mt-4">
        <template>
          <Slide :key="1" class="experience-item">
            <img src="@/assets/images/home/car-types/luxury.jpg" />
            <div class="d-flex">
              <label>Luxury Cars</label>
            </div>
          </Slide>

          <Slide :key="2" class="experience-item">
            <img src="@/assets/images/home/car-types/classic.jpg" />
            <div class="d-flex">
              <label>Classic</label>
            </div>
          </Slide>

          <Slide :key="3" class="experience-item">
            <img src="@/assets/images/home/car-types/convertible.jpg" />
            <div class="d-flex">
              <label>Convertible</label>
            </div>
          </Slide>

          <Slide :key="4" class="experience-item">
            <img src="@/assets/images/home/car-types/sports.jpg" />
            <div class="d-flex">
              <label>Sports Car</label>
            </div>
          </Slide>

          <Slide :key="5" class="experience-item">
            <img src="@/assets/images/home/car-types/caravan.jpg" />
            <div class="d-flex">
              <label>Caravan</label>
            </div>
          </Slide>

          <Slide :key="6" class="experience-item">
            <img src="@/assets/images/home/car-types/suv.jpg" />
            <div class="d-flex">
              <label>SUV</label>
            </div>
          </Slide>

          <Slide :key="7" class="experience-item">
            <img src="@/assets/images/home/car-types/micro.jpg" />
            <div class="d-flex">
              <label>Micro Vehicle</label>
            </div>
          </Slide>
        </template>
        <template #addons>
          <Navigation :index="10" />
        </template>
      </Carousel>
    </div>
  </section>


  <section id="services" class="container">
    <div class="row service">
      <!-- <h1>Extending a Trip</h1> -->
      <div class="col-md-6">
        <img src="@/assets/images/rental/hassle-free.jpg" />
      </div>
      <div class="col-md-6 content">
            <h3>Hassle free transportation</h3>
        <p class="subtitle">
          This service is a two-way thing for the neighborhood and the host. NRide renters
          can have a good luxury functioning car for their convenience.
          Such as for gracing birthday party and wedding occasion, diplomatic
          pick up from airport, travel trip, haulage transportation, moving, and
          many automobile and transportation needs.
        </p>
      </div>
    </div>

    <div class="row service">
      <div class="col-md-6 content">
            <h3>All trips are insured</h3>
        <p class="subtitle">
          All the cars on NRide platform are insured, so you do not need
          to worry about any problems on the road. Our verified hosts have
          proper government approved car insurances which are valid throughout
          the country in case you wish to travel across the state borders.
        </p>
      </div>

      <div class="col-md-6">
        <img src="@/assets/images/rental/insured.jpg" />
      </div>
    </div>

    <div class="row service">
      <div class="col-md-6">
        <img src="@/assets/images/rental/community.jpg" />
      </div>
      <div class="col-md-6 content">
            <h3>Supporting the local community</h3>
        <p class="subtitle">
          Our Rental service is designed to provide transportation needs for
          our Neighborhood and community at large. We provide an innovative
          technology platform that enables you to find the prefect car for
          yourself. The rental vehicles are owned and operated independently 
          by owners and volunteers within the neighborhood.
        </p>
      </div>
    </div>
  </section>

  <section id="experience">
    <div class="container">
      <h1>What our Customers say</h1>
      <Carousel :items-to-show="SlideCount" class="mt-4">
        <template>
          <Slide :key="1" class="experience-item">
            <div class="card p-4">
              <img
                class="ms-auto me-auto"
                src="@/assets/images/rental/testimonial-photos/testi1.png"
              />
              <label class="text-center mb-4 fw-bold">Uzoma Abagun</label>
              <div class="d-flex">
                <label
                  >
nRide don save my life, I swear! D app dey easy to use, and I love say I fit track my driver's location in real-time. 5 stars, no be small thing!                  </label
                >
              </div>
            </div>
          </Slide>
          <Slide :key="2" class="experience-item">
            <div class="card p-4">
              <img
                class="ms-auto me-auto"
                src="@/assets/images/rental/testimonial-photos/testi4.jpg"
              />
              <label class="text-center mb-4 fw-bold">Adaobi Daramola</label>
              <div class="d-flex">
                <label>
I don dey use nRide for one month now, and I dey impressed with how clean and comfortable their vehicles be. Great job, nRide, you guys try!                </label>
              </div>
            </div>
          </Slide>
          <Slide :key="3" class="experience-item">
            <div class="card p-4">
              <img
                class="ms-auto me-auto"
               src="@/assets/images/rental/testimonial-photos/testi1.png"/>
              <label class="text-center mb-4 fw-bold">Zayaan Achebe</label>
              <div class="d-flex">
                <label>
                  D in-app payment system dey seamless and secure, I no get worry. I appreciate say dem get different payment options, including credit/debit cards and mobile wallets.
                </label>
              </div>
            </div>
          </Slide>

          <Slide :key="4" class="experience-item">
            <div class="card p-4">
              <img
                class="ms-auto me-auto"
                src="@/assets/images/rental/testimonial-photos/testi4.jpg"
              />
              <label class="text-center mb-4 fw-bold">Eseosa Nyama</label>
              <div class="d-flex">
                <label>
                  I don get issues with other ride-hailing apps before, but nRide's customer support team dey top-notch. Dem respond to my query promptly and resolve d issue quickly.                >
              </label>
              </div>
            </div>
        </Slide> 

         <Slide :key="5" class="experience-item">
            <div class="card p-4">
              <img
                class="ms-auto me-auto"
                src="@/assets/images/rental/testimonial-photos/testi1.png"
              />
              <label class="text-center mb-4 fw-bold">Akin Adefemi</label>
              <div class="d-flex">
                <label>
                I love say nRide offer different ride options, including economy, luxury, and SUV. Na good thing say I get choices wey fit my budget and preferences.                  
                </label>
              </div>
            </div>
        </Slide>

        </template>
        <template #addons>
          <Navigation :index="10" />
        </template>
      </Carousel>
    </div>
  </section>
      

</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";

export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  computed: {
    SlideCount() {
      let width = screen.width;
      console.log(width);
      if (width < 768)
                    return 1;
                else if (width >= 768 && width < 1400)
                    return 2;
                else
                    return 3;
    },
  },
   methods: {
            handleClick() {
            this.$refs.targetRef.scrollIntoView({ behavior: 'smooth' });
    }

    }
};
</script>




<style scoped lang="scss">
#header {
  height: 95vh;
  display: flex;
  align-items: center;
  background: url("../../assets/images/rental/rental-banner.webp");
  background-repeat: no-repeat;
  background-size: cover;

  .content {
    background: white;
    padding: 3rem 3rem;
    margin-left: 4rem;
    box-shadow: 0 0 16px #fffa;
    width: 40%;
    @media (max-width: 768px) {
      width: 100%;
      margin: 2rem;
      text-align: center;
      padding: 2rem 1.5rem;
    }

    h1 {
      font-size: 3rem;

      @media (max-width: 768px) {
        font-size: 2.5rem;
      }
    }

    p {
      margin-top: 1rem;
      font-size: 1.2rem;
    }

    .width {
      width: 100%;
    }
    .logo-image{
                width : 96px;
                height: 96px;

                 @media (max-width: 768px) {
                    width : 48px;
                    height: 48px;
                }
            }
  }
}
#con {
  padding: 1rem;
  margin-top: 2rem;

  @media (max-width: 768px) {
    padding: 2rem;
  }

  .header {
  h1{
    font-size: 3.5rem;
              @media (max-width: 768px) {
              font-size: 2.5rem;
              }
  }
    p {
      margin: 1rem auto;
      width: 80%;
      font-size: 1.3rem;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .subtitle{
      font-size: 1.2rem;
             width: 100%;
            margin-top: 2rem;
    }
  }
}

#services {
  padding: 1rem;;
  margin-top: 2rem;

  @media (min-width: 1500px) {
    margin-top: 8rem;
  }

    h1{
            font-size: 3.5rem;
              @media (max-width: 768px) {
              font-size: 2.5rem;
              }
      }
  .service {
    align-items: center;
    margin-bottom: 6rem;

    img {
      width: 100%;
      height: auto;
      padding: 2rem;
    }

    .content {

      p.subtitle {
        font-size: 1.2rem;
        width: 100%;
        margin-top: 2rem;
      }
    }

    @media (max-width: 768px) {
      text-align: center;
    }
  }
}



p {
  width: 80%;
}
#experience {
  margin-top: 1rem;
  //   padding: 6rem 0;

  h5 {
    font-weight: bold;
  }

  .experience-item {
    padding: 1rem;
    display: block;
    img {
        width: 90px;
        height: 90px;
        display: block;
        object-fit: cover;
        border-radius: 50rem;
        background: black;
    }

    div {
      display: block;

      label {
        font-size: 1.1rem;
      }
    }
  }

  p {
    font-size: 1.5rem;
    width: 50%;
    margin: 0 auto;
    color: black;
    position: relative;
    padding-bottom: 1rem;

    &:after {
      content: "";
      position: absolute;
      width: 8rem;
      border-radius: 4rem;
      height: 4px;
      background: $theme-color;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: 0;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.size {
  width: 18rem;
  @media (min-width: 768px) {
    width: 39rem;
  }
}
.white {
  color: white;
  text-transform: uppercase;
}
#experience1 {
  margin-top: 1rem;
  h5 {
    font-weight: bold;
  }

  .experience-item {
    padding: 0.5rem;
    display: block;

    img {
      width: 100%;
      height: 18rem;
      display: block;
      object-fit: cover;
      border-radius: 1rem;
      background: black;
    }

    div {
      padding: 1rem;
      display: block;

      label {
        font-size: 0.8rem;
        color: $theme-color;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        font-family: $heading-font;
      }
    }
  }

  p {
    font-size: 1.5rem;
    width: 80%;
    margin: 0 auto;
    color: black;
    position: relative;
    padding-bottom: 1rem;

    &:after {
      content: "";
      position: absolute;
      width: 8rem;
      border-radius: 4rem;
      height: 4px;
      background: $theme-color;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: 0;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

#video {
  padding-top: 2rem;

  @media (min-width: 1500px) {
    padding-top: 8rem;
  }

  .vid {
    align-items: center;
    margin-bottom: 6rem;

    img {
      width: 100%;
      height: auto;
      padding: 2rem;
    }

    .content {
      h1{
        font-size: 3.5rem;
        @media (max-width: 768px) {
              font-size: 2.5rem;
            }
      }

      p.subtitle {
        font-size: 1.2rem;
        width: 70%;
        margin-top: 2rem;

        @media (max-width: 768px) {
          width: 100%;
        }
      }
      .vid-height {
        height: 200px;
        @media (max-width: 768px) {
          height: 40px;
        }
      }
      iframe {
        height: 24rem !important;
      }
    }

    @media (max-width: 768px) {
      text-align: center;
    }
  }
}
</style>