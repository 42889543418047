<template>
    <section class="content">
        <div class="container">
            <h1>Privacy Policy</h1>

            <p class="mt-3"><i>
                "we" or " Neighbours LLC is committed to protecting your privacy and informing you about how your
                information is used. This Privacy Policy explains our practices regarding the collection, use,
                disclosure,
                and protection of information that is collected through our website, mobile application, and any other
                mobile or online service owned and offered by N-RIDE (NeighboursRide) (our “Service”). Capitalized terms
                that are not defined in this Privacy Policy have the meaning given to them in our Terms of Service
            </i>
            </p>

            <h5 class="mt-5">Personal Information We Collect From You</h5>
            <p>
                We collect personal information from you in order to provide our Service and offer you a
                personalized, useful, and efficient experience. The categories of personal information we collect
                can include:
            </p>
            <h6 class="mt-4">Identifying Information</h6>
            <p class=" w-75">You provide us information about yourself, such as your name, age, address, email address,
                university or
                other affiliation, and password, when you register for a Member account with the Service. We also
                collect a copy of your driver’s license and billing and payment information if you utilize our Service.
                If you correspond with us by email, we may retain the content of your email messages, your email
                address, and our responses. We may also retain any messages you send through the Service. You may
                provide us information in User Content you post to the Service.</p>

            <h6 class="mt-4">Payment Information</h6>
            <p class="w-75">N-RIDE (NeighboursRide) collects Hosts' personal payment information, including information
                stored on outside services that Hosts may use with N-RIDE (NeighboursRide), such as PayPal. By
                submitting payment information and/or linking a N-RIDE (NeighboursRide) account with an outside payment
                service such as PayPal, Hosts authorize N-RIDE (NeighboursRide)to access and use this information in
                order to provide Hosts with, and accept payment for, the Service. This allows N-RIDE (NeighboursRide) to
                both confirm a Host's identity and provide Hosts with seamless, hassle-free transactions. Hosts can
                check the current fees, fines, and penalties applicable to use of our Service in our Fee and Commission
                Schedule.</p>
            <p class="w-75">N-RIDE (NeighboursRide) uses Stripe to process payments from Guests related to trips taken
                on our platform. By booking a trip on N-RIDE (NeighboursRide) or otherwise agreeing to N-RIDE’s
                (NeighboursRide) privacy policy, Guests authorize Stripe to collect, use, retain, and disclose their
                personal data in accordance with Stripe's privacy policy, located at https://stripe.com/privacy.</p>


            <h6 class="mt-4">Driver's Record</h6>
            <p class=" w-75">We check Guests' driver records to make sure Guests meet Getaround safety standards. By
                registering with Getaround, you authorize us to access your driver's record from state DMVs, RMVs, and
                other applicable entities, and this authorization extends to N-RIDE (NeighboursRide) throughout the
                entire time you use our Service, so that N-RIDE (NeighboursRide) may re-check your records from time to
                time. You also authorize said entities to release this information to N-RIDE (NeighboursRide).</p>

            <h6 class="mt-4">Vehicle History and Inspection</h6>
            <p class=" w-75">If you own a vehicle that is shared on N-RIDE (NeighboursRide), we may inspect the vehicle,
                collect information identifying the vehicle (like a license plate number, VIN), and obtain a vehicle
                history report to make sure the vehicle is safe for you and other drivers to use. By registering with
                N-RIDE (NeighboursRide), you authorize us to obtain this information, and you authorize applicable
                entities such as DMVs, RMVs, inspection companies, and auto mechanics to release such information to
                N-RIDE (NeighboursRide).</p>

            <h6 class="mt-4">Social Media</h6>
            <p class=" w-75">When you interact with our Service through various social media, such as when you log in
                through Facebook or when you like or post a comment to our Facebook page or interact with us on other
                social media, we may receive information from the social network, such as your profile information,
                profile picture, user name, user ID associated with your social media account, age range, language,
                country, friends list, and any other information you permit the social network to share with third
                parties. The data we receive is dependent upon your privacy settings with the social network. You should
                always review, and, if necessary, adjust your privacy settings on third-party websites and services
                before linking or connecting them to N-RIDE’s (NeighboursRide) Service.</p>
            <p>We use personal information to operate, maintain, and provide to you the features and functionality of
                the Service, as well as to communicate directly with you, such as to send you email messages and push
                notifications, and permit you to communicate with others on the Service or on social media or invite
                others to join the Service. We may also use information to provide personalized content, including
                advertising. We may also send you Service-related emails or messages (e.g., account verification, change
                or updates to features of the Service, technical and security notices). For more information about your
                communication preferences, see Your Choices Regarding Your Information below.</p>


            <h5 class="mt-5">Usage Information We Collect</h5>

            <h6 class="mt-4">Data Gathered from In-Vehicle Hardware</h6>
            <p class=" w-75">The N-RIDE (NeighboursRide) Connect installed in each N-RIDE (NeighboursRide) car allows us
                to gather information about your vehicle use, including time of use, location and movements, speed of
                travel, distance traveled, and other aspects of your vehicle use. We use this information to (i) provide
                you with the Service, (ii) understand your driving and use habits over time and potentially provide you
                with related benefits, such as better insurance rates or other perks for safe driving, (iii) record
                vehicle movements and driver behavior in case of an accident or theft, and (iv) aggregate your data with
                other drivers' data and better understand how people use our Service and how we can make it even better
                for you in the future. We may share this information with third parties as described below.</p>

            <h6 class="mt-4">Location Tracking</h6>
            <p class=" w-75">The location of all N-RIDE (NeighboursRide)cars you use is tracked by GPS (or equivalent),
                cellular, WiFi, Bluetooth, or similar technologies. Location information is tracked both on your N-RIDE
                (NeighboursRide)-enabled smart phone and by the vehicle's N-RIDE (NeighboursRide) Connect. Location
                information is shared in limited ways. We monitor the location of all cars to provide our Service, to
                prevent theft, and to allow us to locate you in case of accident, emergency, lock-out, etc. We may
                maintain location data in order to aggregate trends and metrics and may share de-identified and/or
                aggregate location and movement data with third parties for advertising, research, analytics, and other
                purposes. We may share location information in some instances as described below.</p>

            <h6 class="mt-4">Third Party Hardware Devices</h6>
            <p class=" w-75">As a Guest, you acknowledge and understand that dashboard cameras, mileage and location
                tracking devices, and other similar devices may be installed in the cars listed on N-RIDE
                (NeighboursRide). You further acknowledge and understand that any such device installed in a Host's car
                is installed at the Host's sole discretion, without direction from N-RIDE (NeighboursRide), and that
                N-RIDE (NeighboursRide) makes no warranties or representations regarding the conformity of such devices
                to any laws applicable to their usage or installation. Any concerns about the presence, operation,
                functionality, and data storage of these devices must be addressed directly with the Hosts who has
                installed them. N-RIDE (NeighboursRide) does not collect or store any data generated by such
                hardware.</p>

            <h6 class="mt-4">Connecting Your Phone to Cars</h6>
            <p class=" w-75">As a Host or Guest, you acknowledge and understand that establishing a data connection
                between a Car and your phone, either via Bluetooth® or with a physical cable, may cause the Car to
                access, store, and display the contents of your phone. Such contents may include location data, contact
                information, and digital files stored on your phone. You also acknowledge and understand that such
                contents may be accessible to other Hosts or Guests (as applicable) unless you delete all data from the
                Car that has been transferred from your phone. We encourage you to delete such data before sharing your
                car or completing your trip (as applicable). N-RIDE (NeighboursRide) is not responsible for any failure
                to delete such data, whether or not accessed by other Hosts or Guests, and N-RIDE (NeighboursRide) is
                unable to delete any such data on behalf of any Host or Guest.</p>


            <h5 class="mt-5">Use of Cookies and Other Technology to Collect Information</h5>
            <p>When you visit our website or use our mobile app or Service, we and our business partners may collect
                certain information about your computer or device through technology such as cookies, web beacons, log
                files, or other tracking/recording tools. The information we collect through the use of tracking
                technologies includes but is not limited to IP address, browser information, referring/exit pages and
                URLs, click stream data and information about how you interact with links on the website, mobile app, or
                Service, domain names, landing pages, page views, and cookie data that allows us to uniquely identify
                your browser and track your browsing behavior on our site. We may also use clear gifs or other
                technology in HTML-based emails sent to our users to track which emails are opened and which links are
                clicked by recipients. Some or all of this data may be combined with other personally identifying
                information described above. We may also collect analytics data, or use third-party analytics tools, to
                help us measure traffic and usage trends for the Service and to understand more about the demographics
                of our users. These tools collect information sent by your browser or mobile device, including the pages
                you visit, your use of third party applications, and other information that assists us in analyzing and
                improving the Service. We may work with third parties to employ technologies, including the application
                of statistical modeling tools, which attempt to recognize you across multiple devices. Although we do
                our best to honor the privacy preferences of our visitors, we are not able to respond to Do Not Track
                signals from your browser at this time.</p>

            <p>When you access our Service by or through a mobile device, we may receive or collect and store a unique
                identification number associated with your device or our mobile application (including, for example, a
                UDID, Unique ID for Advertisers (“IDFA”), Google Ad ID, or Windows Advertising ID or other identifier),
                mobile carrier, device type, model and manufacturer, mobile device operating system brand and model,
                phone number, and, depending on your mobile device settings, your geographical location data, including
                GPS coordinates (e.g. latitude and/or longitude), WiFi location, or similar information regarding the
                location of your mobile device.</p>

            <p>We use or may use the data collected through these technologies to: (i) remember information so that you
                will not have to re-enter it during your visit; (ii) provide custom, personalized content and
                information, including advertising; (iii) identify you across multiple devices; (iv) provide and monitor
                the effectiveness of our Service; (v) monitor aggregate metrics such as total number of visitors,
                traffic, usage, and demographic patterns on our website and our Service; (vi) diagnose or fix technology
                problems; and (vii) otherwise to plan for and enhance our service.</p>


            <h5 class="mt-5">Calls and text messages</h5>
            <p>We enable users to call or text each other through the Service. For example, we enable Hosts and Guests
                to call or text each other without disclosing their telephone numbers. To provide this functionality,
                N-RIDE (NeighboursRide) and our third-party service provider receive some information regarding the
                calls or texts, including the date and time of the call or text, and the content of the text messages.
                We may also use this information for customer support services (including to resolve disputes between
                users), for safety and security purposes, and to improve the Service.</p>

            <h6 class="mt-4">Carrier Consent</h6>
            <p class="w-75">You authorize your wireless operator (MTN, GLO, ETISALA, or any other branded wireless
                operator) to
                disclose to the N-RIDE (NeighboursRide) and its third-party service providers your mobile number, name,
                address, email, network status, customer type, customer role, billing type, mobile device identifiers
                (IMSI and IMEI) and other subscriber status and device details, if available, solely to verify your
                identity and prevent fraud for the duration of your use of the Services.</p>

            <h6 class="mt-4">Telephone Consumer Protection Act (TCPA) Consent</h6>
            <p class="w-75">Notwithstanding any current or prior election to opt in or opt out of receiving calls or SMS
                messages
                (including text messages) from us, our agents, representatives, affiliates, or anyone calling on our
                behalf, you expressly consent to be contacted by us, our agents, representatives, affiliates, or anyone
                calling on our behalf for any and all purposes arising out of or relating to your Service, at any
                telephone number, or physical or electronic address you provide or at which you may be reached. You
                agree that we may contact you in any way, including SMS messages or text messages, calls using
                prerecorded messages or artificial voice, and calls and messages delivered using auto telephone dialing
                system or an automatic texting system. Automated messages may be played when the telephone is answered,
                whether by you or someone else. In the event that an agent or representative calls, he or she may also
                leave a message on your answering machine, voice mail, or send one via text.</p>

            <p class="w-75">You consent to receive SMS messages (including text messages), calls and messages (including
                prerecorded
                and artificial voice and autodialed) from us, our agents, representatives, affiliates or anyone calling
                on our behalf at the specific number(s) you have provided to us, or numbers we can reasonably associate
                with your account (through skip trace, caller ID capture or other means), with information or questions
                about your use of the Service. You certify, warrant and represent that the telephone numbers that you
                have provided to us are your contact numbers. You represent that you are permitted to receive calls at
                each of the telephone numbers you have provided to us. You agree to promptly alert us whenever you stop
                using a particular telephone number.</p>

            <p class="w-75">Your cellular or mobile telephone provider will charge you according to the type of plan you
                carry. You
                also agree that we may contact you by email, using any email address you have provided to us or that you
                provide to us in the future.</p>

            <p class="w-75">We may listen to and/or record phone calls between you and our representatives without
                notice to you as
                permitted by applicable law. For example, we listen to and record calls for training and quality
                monitoring purposes.</p>


            <h5 class="mt-5">How We Share Your Information</h5>
            <p>We may share your personal information in the instances described below. For further information on your
                choices regarding your information, see Your Choices Regarding Your Information below.</p>
            <p>N-RIDE (NeighboursRide) may share your personally identifiable information with third party vendors,
                consultants or other service providers that perform services on our behalf or otherwise help provide the
                Service to you, under reasonable confidentiality terms.</p>
            <p>We may share your information in connection with any company transaction, such as a merger, sale of all
                or a portion of company assets or shares, reorganization, financing, change of control or acquisition of
                all or a portion of our business by another company or third party. or in the event of bankruptcy or
                related or similar proceedings.</p>
            <p>Other brands owned or controlled by N-RIDE (NeighboursRide), and other companies owned by or under common
                ownership as N-RIDE (NeighboursRide), which also includes our subsidiaries (i.e., any organization we
                own or control) or our ultimate holding company (i.e., any organization that owns or controls us) and
                any subsidiaries it owns. These companies will use your personal information in the same way as we can
                under this Policy.</p>
            <p>We may share license plate information with governmental agencies to ensure compliance with local parking
                rules and restrictions, to respond or settle a citation issued during a trip, or in accordance with a
                parking program or partnership operated with a governmental agency.</p>
            <p>We may use personally identifiable information to investigate damage claims connected to trips taken
                and/or cars listed on our N-RIDE (NeighboursRide), and we may disclose personally identifiable
                information to a third party in connection with processing such claims under reasonable confidentiality
                restrictions.</p>
            <p>We may also disclose personal information if we believe it to be reasonably necessary to (i) satisfy any
                applicable law or regulation and comply with legal process, such as search warrant, subpoena or court
                order, or other government request served on us or on our affiliates; (ii) to enforce our Terms of
                Service, including to investigate potential violations thereof; (iii) take precautions against
                liability, to investigate and defend ourselves against any third-party claims or allegations, or to
                protect the security or integrity of our site; and (iv) to exercise or protect the rights, property, or
                personal safety of N-RIDE (NeighboursRide), our Users, or others. Typically, our policy is not to share
                personal information and location data with governmental agencies, except as pursuant to a search
                warrant, subpoena, court order, or as otherwise compelled to do so by legal process, though there may be
                circumstances in which we may disclose such information without such legal process if we reasonably
                believe such disclosure is necessary to protect the health and safety of an individual, prevent or
                mitigate the harm of crime or illegal activity, or other compelling circumstance. We will use our best
                effort to provide you notice of a law enforcement request for your personal information before we
                release such information, unless we are not permitted to do so.</p>
            <p>We may use and share your personally identifiable information with consumer reporting vendors such as
                credit agencies in connection with a trip that has been booked on our platform. You authorize N-RIDE
                (NeighboursRide) to share your personally identifiable information with such vendors to assist N-RIDE
                (NeighboursRide) in determining your eligibility to book trips on our platform. N-RIDE (NeighboursRide)
                may share your personally identifiable information to obtain your consumer history in the form of a
                credit score, a background check, and/or an auto insurance score, in all instances solely in accordance
                with applicable law. You acknowledge that N-RIDE (NeighboursRide) may (but is not obligated to) use such
                consumer history in connection with your N-RIDE (NeighboursRide) account except where such usage is
                prohibited by applicable law.</p>

            <h6 class="mt-4">Sharing of Your Information with Business Partners; Expanded Service</h6>
            <p class="w-75">We may use information about you in partnership with outside companies in the course of
                providing you with additional services related to N-RIDE (NeighboursRide), such as ride-sharing or
                parking benefits. When you purchase, register, or otherwise express interest in a product or service
                offered by a third party through N-RIDE (NeighboursRide) or sponsored on our site, you consent to our
                sharing of your personal information with those parties. When you choose to participate in rewards
                programs, contests, discount offers, or other programs that involve third parties, you authorize N-RIDE
                (NeighboursRide) to share your personal information with those parties, who may use such information in
                accordance with their own privacy policies. In these cases we will only share the information reasonably
                needed to provide you with service. Information that is shared with third parties is subject to the
                privacy policies of those parties, which may differ from N-RIDE’s (NeighboursRide)'s privacy
                policies.</p>

            <h6 class="mt-4">Sharing and Using Aggregate or Anonymized Information</h6>
            <p class="w-75">We may aggregate, anonymize, or otherwise strip data of all personally identifying
                characteristics and may share that anonymized and/or aggregated data with third parties, in a manner
                that cannot reasonably identify any individual. For example, we may share de-identified location data
                and location history with third parties that analyze location and movement trends, and we may share
                information that would identify a particular as well as the driving history, and general demographics of
                the drivers (i.e., gender and age bracket) of that car in order to analyze, develop and improve our
                partner programs.</p>

            <hr class="mt-5 mb-5">

            <h5>Your Choices About Your Information</h5>
            <h6 class="mt-4">Profile and Data Sharing Settings</h6>
            <p class="w-75">You can change your account information and preferences at any time by logging onto your
                account.</p>

            <h6 class="mt-4">Communications</h6>
            <p class="w-75">We will not sell your contact information to third parties for marketing purposes.</p>
            <p class="w-75">We may communicate with you via in-app notifications, email, SMS text, or other method. You
                control when and how (e.g. email, SMS, etc.) you would like to receive messages, and you may be able to
                opt-out of receiving certain types of messages. </p>

            <p class="w-75">We may send you notifications of activity on the Service to the email address you give us,
                in accordance with any applicable privacy settings. For instance, if you are a Host, we will send you
                information about your car's availability status. You can turn these notifications off or modify how
                often and in what form you receive them.</p>

            <p class="w-75">N-RIDE (NeighboursRide) may send you other messages in the course of operation of our
                Service, such as notifications from other Users, updates about Service changes and new features, and
                important news that may impact your use and enjoyment of N-RIDE (NeighboursRide). These communications
                may contain banners, ads, or promotional material provided by third parties. If you click on the third
                party link, you will be taken to that service, and your information will thereafter be subject to that
                party’s privacy policy. You may be able to opt-out of receiving promotional emails by clicking the
                “unsubscribe” button at the footer of promotional email communications. Note that you are not permitted
                to unsubscribe or opt-out of non-promotional messages regarding your account.</p>

            <p class="w-75">By providing N-RIDE (NeighboursRide) your email address, you consent to our using the email
                address to send you Service-related notices, including any notices required by law, in lieu of
                communication by postal mail.</p>

            <h6 class="mt-4">Third Party Tracking and Advertising</h6>
            <p class="w-75">We may share, or we may permit third party ad networks, social media companies, and other
                third party services to collect information about the browsing behavior of our users of Service through
                cookies, social plug-ins, or other tracking technology. We may permit third party online advertising
                networks to collect information about your use of our Service over time, including location information,
                so that they may play or display ads that may be relevant to your interests on our Service as well as on
                other websites or services, or on other devices you may use. Typically, the information is collected
                through cookies or similar tracking technologies. You may be able to “opt out” of the collection of
                information through cookies or other tracking technology by actively managing the settings on your
                browser or mobile device. Please refer to your browser’s or mobile device’s technical information for
                instructions on how to delete, disable, and/or limit the use of cookies or other tracking/recording
                tools. Depending on your mobile device, you may not be able to control all tracking technologies through
                your device settings. You may also be able to limit interest-based advertising through the settings on
                your browser or device. For example, you may be able to opt-out of some interest-based advertising on
                your mobile device by selecting “limit ad tracking” (iOS) or “opt-out of interest based ads” (Android).
                To learn more about interest-based advertising and how you may opt-out of some of this tracking, you may
                wish to visit the Network Advertising Initiative’s online resources, at
                http://www.networkadvertising.org/choices and/or the DAA’s resources at www.aboutads.info/choices. You
                may also be able to opt-out of some – but not all – interest-based ads served by mobile ad networks by
                visiting http://youradchoices.com/appchoices and downloading the mobile AppChoices app. If you have any
                questions about third party tracking and advertising, you can contact us directly at
                Info@our-neighbours.com.</p>


            <h6 class="mt-4">Data Retention</h6>
            <p class="w-75">Following termination or deactivation of your Member account, N-RIDE (NeighboursRide) may
                retain your information for a commercially reasonable time for backup, archival, or audit purposes, or
                to maintain and improve the Service -- for instance, for the safety of other Users, we may need access
                to information about cars you have used in case one of those cars is in an accident. We may maintain
                location information and other usage data for record-keeping and analytics purposes. Furthermore, N-RIDE
                (NeighboursRide) may retain and continue to use indefinitely all information (including User Content)
                contained in your communications to other Users or posted to public or semi-public areas of the Service
                after termination or deactivation of your Member account. Please contact us at info@our-neighbours.com
                if you wish to delete your account information from our systems, though we may not be able to delete all
                information, as noted in this Privacy Policy.</p>

            <h6 class="mt-4">How We Protect Your Information</h6>
            <p class="w-75">N-RIDE (NeighboursRide) cares about the security of your information and uses commercially
                reasonable
                physical, administrative, and technological safeguards to preserve the integrity and security of all
                information we collect and that we share with our service providers. However, no security system is
                impenetrable, and we cannot guarantee the security of our systems 100%. In the event that any
                information under our control is compromised as a result of a breach of security, we will take
                reasonable steps to investigate the situation and, where appropriate, notify those individuals whose
                information may have been compromised and take other steps, in accordance with any applicable laws and
                regulations. To protect your privacy and security, we take reasonable steps (such as requesting a unique
                password) to verify your identity before granting you access to your account. You are responsible for
                maintaining the secrecy of your unique password and account information and for controlling access to
                your email communications from N-RIDE (NeighboursRide), at all times.</p>

            <h6 class="mt-4">Children's Privacy</h6>
            <p class="w-75">Protecting the privacy of young children is especially important. For that reason, N-RIDE
                (NeighboursRide) does not knowingly collect or solicit personal information from anyone under the age of
                13 or knowingly allow such persons to register as Members. If you are under 13, please do not send any
                information about yourself to us, including your name, address, telephone number, or email address. In
                the event that we learn that we have collected personal information from a child under age 13 without
                verification of parental consent, we will delete that information as quickly as possible. If you believe
                that we might have any information from or about a child under 13, please contact us here.</p>

            <hr class="mt-5 mb-5">
            <h5>Links to Other Websites</h5>
            <p>N-RIDE (NeighboursRide) is not responsible for the practices employed by websites linked to or from the
                Service, nor the information or content contained therein. Please remember that when you use a link to
                go from the Service to another website, our Privacy Policy is no longer in effect. Your browsing and
                interaction on any other website, including those that have a link on our website, is subject to that
                website's own rules and policies. Please read over those rules and policies before proceeding.</p>

            <h6 class="mt-4">Notification Procedures</h6>
            <p>It is our policy to provide notifications, whether such notifications are required by law or are for
                marketing or other business related purposes, to you via email notice, written or hard copy notice, or
                through conspicuous posting of such notice on the Service, as determined by N-RIDE (NeighboursRide) in
                its sole discretion. We reserve the right to determine the form and means of providing notifications to
                you, provided that you may opt out of certain means of notification, as described in this Privacy
                Policy.</p>

            <h6 class="mt-4">Changes to Our Privacy Policy</h6>
            <p>If we change our privacy policies and procedures, we will post those changes on the Service to keep you
                aware of what information we collect, how we use it, and under what circumstances we may disclose it.
                Changes to this Privacy Policy are effective when they are posted on this page.</p>


            <p class="mt-5"><i>For any questions on this privacy policy, please contact us by submitting a support
                request here or by e-mail at <a href="mailto:info@neighboursRide.com">info@neighboursRide.com
</a> Neighbours
                LLC.</i></p>

        </div>
    </section>

</template>

<script>
    export default {
        name: "PrivacyPolicy"
    }
</script>

<style scoped lang="scss">
    .content {
        margin: 6rem 0;
    }
</style>