<template>
    <section id="header">
        <div class="content">
      <img class = "logo-image" src="@/assets/logo_transparent.png" />
      <h1>Reserve a car for corporate use</h1>
      <p>Contact us for corporate rates and get the best deal</p>
      <span class="more" @click="handleClick()">Learn more about this application</span>
      <p> <button @click="handleClick()" class="color">Proceed to book</button></p>
    </div>
    </section>
  <section>
    <div class="card" ref="targetRef">
      <h3 class="text-center text-black">Book your Corporate Ride/Driver</h3>
      <button for="firstname" class="text-center" @click="openEmail">For any kind of support, contact &nbsp; <span class="underline">info@neighboursRide.com</span></button>
      <form @submit.prevent="handleSubmit" class="mt-3">
        <div class="d-flex">
          <div class="flex-fill">
            <label for="firstname" class="form-label">First Name <span style="color:red">*</span></label>
            <input
              type="text"
              class="form-control"
              id="firstname"
              required
              placeholder="First Name"
              aria-describedby="firstname"
              v-model.trim="formData.firstname"
            />
            <error
              v-if="(!this.firstname || this.firstname === '') && isSubmitClick"
              >* Required</error
            >
          </div>
          <div class="ms-3 flex-fill">
            <label for="lastname" class="form-label">Last Name <span style="color:red">*</span></label>
            <input
              type="text"
              class="form-control"
              id="lastname"
              required
              placeholder="lastname"
              aria-describedby="lastname"
              v-model.trim="formData.lastname"
            />
            <error
              v-if="(!this.lastname || this.lastname === '') && isSubmitClick"
              >* Required</error
            >
          </div>
        </div>
        <div class="d-flex">
          <div class="flex-fill">
            <label for="email" class="form-label">Email <span style="color:red">*</span> </label>
            <input
              type="text"
              class="form-control"
              id="email"
              required
              placeholder="Email"
              aria-describedby="emailHelp"
              v-model.trim="formData.email"
              @blur="validateEmail"
            />
            <error v-if="this?.msg['email']">{{ this?.msg["email"] }}</error>
             <error
              v-if="(!this.email || this.email === '') && isSubmitClick"
              >* Required</error
            >
          </div>
          <div class="ms-3 flex-fill">
            <label for="phone" class="form-label">Phone <span style="color:red">*</span></label>
            <input
              type="phone"
              @input="acceptNumber"
              required
              class="form-control"
              id="phone"
              placeholder="Phone"
              aria-describedby="emailHelp"
              v-model.trim="formData.phone"
            />
          </div>
        </div>

        <div class="mb-3">
          <label for="companyname" class="form-label"
            >Company or Organization Name</label
          >
          <input
            type="text"
            class="form-control"
            placeholder="Company or Organization Name"
            id="companyname"
            v-model.trim="formData.companyname"
            aria-describedby="companyname"
          />
        </div>

       <label for="address" class="form-label">Address</label>
         <div class="d-flex">
          <div class="flex-fill">
            <label for="street" class="form-label">Street</label>
            <input
            placeholder="Street"
            type="text"
            class="form-control"
            id="street"
            v-model.trim="formData.street"
            aria-describedby="street"
            />
          </div>
          <div class="ms-3 flex-fill">
            <label for="city" class="form-label">City</label>
            <input
              placeholder="City"
             type="text"
            class="form-control"
            id="city"
            v-model.trim="formData.city"
            aria-describedby="city"
            />

          </div>
          <div class="ms-3 flex-fill">
            <label for="state" class="form-label">State</label>
            <input
              placeholder="State"
             type="text"
            class="form-control"
            id="state"
            v-model.trim="formData.state"
            aria-describedby="state"
            />
          </div>
        </div>

        <div class="d-flex">
          <div class="flex-fill">
            <label for="service" class="form-label">
              What service would you require today?</label
            >
            <select
              class="form-select"
              v-model="formData.service"
              aria-label="Default select example"
            >
              <option selected value="">Select any</option>
              <option value="Rental">Rental</option>
              <option value="Driver">Driver</option>
              <option value="RentalwithDriver">Rental with Driver</option>

            </select>
          </div>
          <div class="ms-3 flex-fill">
            <label for="ride" class="form-label"
              >What class of ride would you require for your rental?</label
            >
            <select
              class="form-select"
              aria-label="Default select example"
              v-model="formData.ride"
            >
              <option selected value="">Select any</option>
              <option value="Truck">Truck</option>
              <option value="Sedan">Sedan</option>
              <option value="Sport">Sport</option>
              <option value="Lorry">Lorry</option>
              <option value="Vintage">Vintage</option>
            </select>
          </div>
        </div>

        <div class="d-flex">
          <div class="flex-fill">
            <label for="cars" class="form-label"
              >Number of Cars you wish to Book</label
            >
            <input
              type="number"
              class="form-control"
              min="1"
              id="cars"
              placeholder="Number of Cars"
              aria-describedby="cars"
              v-model.trim="formData.cars"
            />
          
          </div>
    <div class="ms-3 flex-fill">
    <label for="selectedYear" class="form-label">Select Year</label>
    <select id="year-select" v-model="formData.selectedYear" class="form-select">
    <option value="">Select Year</option>
      <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
    </select>
      </div>
        </div>
   
        <p v-show="!formIsValid" class="error">{{ errorMsg }}</p>
        <p style="color: #e25555; font-size:small; font-weight: bold;" class="mb-0">** Required</p>
        <button type="submit" class="btn btn-theme m-0">Submit</button>
      </form>
    </div>
  </section>

</template>

<script>
export default {
  name: "CorporateService",
  data() {
    return {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      companyname: "",
      street:"", 
      city:"",
      state :"",
      service: "",
      ride: "",
      yourrental: "",
      yourrentalVal: "",
      formIsValid: true,
      errorMsg: "",
      cars: "",
      isSubmitClick: false,
      msg: { email: "" },
      selectedYear: new Date().getFullYear(),
      years: [],

      formData: {
      firstname: "",
      lastname:"",
      email:"",
      phone:"",
      companyname:"",
      street:"", 
      city:"",
      state:"",
      service:"",
      ride:"",
      cars:"",
      years:"",  
      }
    };
  },
  created() {
    this.generateYears();
  },
  
  methods: {
    validateEmail() {
      if (
        /^[a-zA-Z0-9_\-.]+[@][a-z]+[.]+((com)|(co.in)|(net)|(in)|(org)|(info)|(biz))$/.test(
          this.formData.email
        )
      ) {
        // this.formData.email="valid";
        this.msg["email"] = "";
      } else {
        this.msg["email"] = "Please enter a valid email address";
        // this.email="invalid";
      }
    },
    acceptNumber() {
      var x = this.formData.phone.replace(/\D/g, "").match(/(\d{0,10})/);
      this.formData.phone = x[1] ? x[1] : "";
    },
    checkForm(e) {
      const x = {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        phone: this.phone,
        companyname: this.companyname,
        street:this.street, 
        city:this.city,
        state :this.state,
         service: this.service,
         ride: this.ride,
        cars: this.cars,
      };
      this.isSubmitClick = true;
      console.log(x,'ftf');
      if (
        Object.values(x).every((v) => (v != null && v != "") || v?.length > 0)
      ) {
        //API will be called here
        console.log(
          [x.firstname, x.lastname].every((v) => v != null && v?.length > 0)
        );
        console.log([x.email].every((v) => v != null && v?.length > 0));
        console.log([x.phone].every((v) => v != null && v?.length > 0));
        console.log([x.companyname].every((v) => v != null && v?.length > 0));
        console.log([x.address].every((v) => v != null && v?.length > 0));
        console.log([x.service].every((v) => v != null && v?.length > 0));
        console.log([x.ride].every((v) => v != null && v?.length > 0));
        console.log([x.yourrental].every((v) => v != null && v?.length > 0));
        console.log([x.cars].every((v) => v != null && v?.length > 0));
      }

      e.preventDefault();
    },

        handleSubmit() {
          const to = "info@neighboursRide.com"
     const{ firstname,
      lastname,
      email,
      phone,
      companyname,
      street, 
      city,
      state,
      service,
      ride,
      cars,
      selectedYear} = this.formData
      const subject = `Form Submission from ${firstname}`;
      const body = `
      Firstname: ${firstname},
      Lastname: ${lastname},
      Email: ${email},
      Phone: ${phone},
     Companyname: ${companyname},
      Street: ${street}, 
      City: ${city},
      State: ${state},
      Service: ${service},
      Ride: ${ride},
      Cars: ${cars},
     SelectedYear: ${selectedYear}`;
      const mailtoLink = `mailto:${to}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

      window.location.href = mailtoLink;
    },
      openEmail() { 
      window.location.href = "mailto:info@neighboursRide.com";
    },
     handleClick() {
            this.$refs.targetRef.scrollIntoView({ behavior: 'smooth' });
    },
      generateYears() {
      const currentYear = new Date().getFullYear();
      for (let i = 0; i <= 15; i++) {
        this.years.push(currentYear - i);
      }
    },
    }
};
</script>

<style scoped lang="scss">
section {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}
#header {
  height: 95vh;
  display: flex;
  align-items: center;
 background: url("../../assets/images/corporate/corporate-banner.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  
  .content {
    background: white;
    padding: 3rem 3rem;
   // margin-left: 52%;
    margin-left: 4rem;
    box-shadow: 0 0 16px #fffa;
    width: 40%;
    @media (max-width: 768px) {
      width: 100%;
      margin: 2rem;
      text-align: center;
      padding: 2rem 1.5rem;
    }

    h1 {
      font-size: 3rem;

      @media (max-width: 768px) {
        font-size: 2.5rem;
      }
    }

    p {
      margin-top: 1rem;
      font-size: 1.2rem;
    }
  .logo-image{
                width : 96px;
                height: 96px;

                 @media (max-width: 768px) {
                    width : 48px;
                    height: 48px;
                }
            }
    .width {
      width: 100%;
    }
  }
  .color{
    color: green;
    font-size: medium;
  }
}
.card {
  width: 45%;
  margin: 0 auto;
  padding: 3rem 2rem;
  box-shadow: 0 0 16px #0003;
  @media (max-width: 768px) {
    width: 100%;
    margin: 2rem;
    text-align: center;
    padding: 2rem 1.5rem;
  }
  h1 {
    text-align: center;
  }
}

label {
  color: #015032;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
}

input{
    margin-bottom: 1rem;
}

error {
  color: red;
  font-size: 0.8rem;
}

.underline{
  text-decoration: underline;
}

button{
  color: #e25555;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  cursor: pointer;
  border: none;
  background: white;
}

</style>